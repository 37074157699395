import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import "./../style/projects.scss"

const Projects = () => {
  const {
    github: {
      repositoryOwner: {
        repositories: { edges },
      },
    },
  } = useStaticQuery(graphql`
    {
      github {
        repositoryOwner(login: "inhaq") {
          repositories(
            first: 12
            orderBy: { field: UPDATED_AT, direction: DESC }
          ) {
            edges {
              node {
                id
                name
                url
                description
                updatedAt
                collaborators {
                  totalCount
                }
                repositoryTopics(first: 3) {
                  edges {
                    node {
                      id
                      topic {
                        name
                      }
                    }
                  }
                }
                primaryLanguage {
                  color
                }
              }
            }
          }
        }
      }
    }
  `)

  const dateConvert = (yyyymmdd) => {
    yyyymmdd = yyyymmdd.split("T").shift()
    return yyyymmdd.replace(/(\d{4})-(\d\d)-(\d\d)/, function (_, y, m, d) {
      m = "???,Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec".split(",")[+m]
      return [d, m, y].join("-")
    })
  }

  let clr

  return (
    <>
      <SEO title="Projects" />
      <div className="projects">
        {edges.map(({ node }) => (
          <div
            key={node.id}
            className="single-project"
            style={{
              borderTop: `5px solid ${
                node.primaryLanguage ? node.primaryLanguage.color : "#886294"
              }`,
              boxShadow: `0px 1px 2px ${
                node.primaryLanguage ? node.primaryLanguage.color : "#886294"
              }`,
            }}
          >
            <span className="d-none">
              {
                (clr = node.primaryLanguage
                  ? node.primaryLanguage.color
                  : "#886294")
              }
            </span>

            <div className="project-head">
              <h4 className="heading">{node.name}</h4>
              <p className="sub-head">{node.description}</p>
              <p className="solo-tag">
                {node.collaborators.totalCount > 1
                  ? `Collaboration`
                  : `Solo Project`}
              </p>
              <p className="updated">
                Last Updated on {dateConvert(node.updatedAt)}
              </p>
            </div>
            <div className="tags">
              {node.repositoryTopics.edges.map(({ node }) => (
                <span
                  className="tag"
                  style={{
                    background: `${clr}`,
                    color: `${clr === "#f1e05a" ? "#000" : "#fff"}`,
                  }}
                  key={node.id}
                >
                  {node.topic.name}
                </span>
              ))}
            </div>

            {/*<a href={node.url} target="_blank" rel="noopener noreferrer">*/}
            {/*  <button className='prime view'>Visit Github Repo</button>*/}
            {/*</a>*/}
            <a href={node.url} target="_blank" rel="noopener noreferrer">
              <input
                type="button"
                className="prime view"
                value="Visit Github Repo"
              />
            </a>
          </div>
        ))}
      </div>
    </>
  )
}

export default Projects
